import { useState } from "react";
import { useForm } from "react-hook-form";
const LoginForm = () => {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [res,setRes] = useState(null)
  const onSubmit = async(data) =>{
    const formData = new FormData()
    formData.append('id',data.id)
    formData.append('password',data.password)
    fetch('/api/login',{
      method: 'POST',
      body: formData
    }).then(d => {
      console.log(d)
      if(d.status !== 200){
        return setRes(false)
      }else{
        return setRes(true)
      }
    })
  } ;


  return(
    <div className="w-full max-w-xs">
      <form
        className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
        onSubmit={handleSubmit(onSubmit)}>
        <label className="block text-gray-700 text-sm font-bold mb-2"/>ID {errors.password && <span> This field is required</span>}
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          {...register("id")}
          type="email" placeholder="ID"
        />
        <label className="block text-gray-700 text-sm font-bold mb-2"/>Password {errors.password && <span> This field is required</span>}
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          {...register("password", { required: true }) }
          type="password" placeholder="PASSWORD"
        />
        <button>
          <input type="submit" />
        </button>
        <LoginCheck res = {res} />
      </form>
    </div>
  )
}
export default LoginForm;

const LoginCheck = (props) =>{
  console.log(props)
  if(props.res===false){
    return <div>login fail</div>
  }
  if(props.res===true){
    return <div>login succses</div>
  }
  return <></>
}