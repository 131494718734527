import { rest } from 'msw'
import { v4 as uuidv4 } from 'uuid'
export const handlers = [
  rest.post('/api/survay/new',(req,res,ctx) => {
    return res(
      ctx.status(200),
      ctx.json([uuidv4(),uuidv4(),uuidv4()])
    )
  }),
  rest.post('/api/survay/get',(req,res,ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        "company": "802872c4-1813-4df8-a032-1407362c94a2",
        "item": {
          "title": "tesuto1",
          "survay": [
            {
              "id": "611117b4-8848-4c1a-8587-10fc190a22ab",
              "question": "背中・腰・お尻のどこかに痛みがありますか？",
              "answers": [
                "痛くない",
                "少し痛い",
                "中程度痛い",
                "かなり痛い",
                "ひどく痛い"
              ]
            },
            {
              "id": "96b75cb3-8a2e-44fe-abc8-48b9641337ef",
              "question": "頚・肩・腕・手のどこかに痛み（しびれも含む）がありますか？",
              "answers": [
                "痛くない",
                "少し痛い",
                "中程度痛い",
                "かなり痛い",
                "ひどく痛い"
              ]
            },
            {
              "id": "9e1ab6e3-a330-4896-bda1-b346354106c3",
              "question": "下股（脚のつけね、太もも、膝、ふくらはぎ、すね、足首、足）のどこかに痛み（しびれも含む）がありますか？",
              "answers": [
                "痛くない",
                "少し痛い",
                "中程度痛い",
                "かなり痛い",
                "ひどく痛い"
              ]
            },
            {
              "id": "2039c7b0-d14e-4005-b767-01973364b3f1",
              "question": "ふだんの生活で身体を動かすのはどの程度つらいと感じますか？",
              "answers": [
                "つらくない",
                "少しつらい",
                "中程度つらい",
                "かなりつらい",
                "ひどくつらい"
              ]
            },
            {
              "id": "956d14cb-650f-49a8-964e-49409dd88320",
              "question": "ベッドや寝床から起きたり、横になったりするのはどの程度困難ですか？",
              "answers": [
                "困難でない",
                "少し困難",
                "中程度困難",
                "かなり困難",
                "ひどく困難"
              ]
            },
            {
              "id": "693f1fe9-6978-4d16-b356-7e2a641dedb6",
              "question": "腰掛から立ち上げるのはどの程度困難ですか？",
              "answers": [
                "困難でない",
                "少し困難",
                "中程度困難",
                "かなり困難",
                "ひどく困難"
              ]
            },
            {
              "id": "bee59235-d7cd-41bc-928c-ab0873a0eb10",
              "question": "家の中を歩くのはどの程度困難ですか？",
              "answers": [
                "困難でない",
                "少し困難",
                "中程度困難",
                "かなり困難",
                "ひどく困難"
              ]
            },
            {
              "id": "6c319f75-f7fb-49fe-87b3-a3ac43f66e88",
              "question": "シャツを着たり脱いだりするのはどの程度困難ですか？",
              "answers": [
                "困難でない",
                "少し困難",
                "中程度困難",
                "かなり困難",
                "ひどく困難"
              ]
            },
            {
              "id": "7a8c0192-a605-4d32-bea9-2fb476bcb59e",
              "question": "ズボンやパンツを着たり脱いだりするのはどの程度困難ですか？",
              "answers": [
                "困難でない",
                "少し困難",
                "中程度困難",
                "かなり困難",
                "ひどく困難"
              ]
            },
            {
              "id": "bea13aa1-f552-4ac0-be08-cc773ffcf2be",
              "question": "トイレで用足しをするのはどの程度困難ですか？",
              "answers": [
                "困難でない",
                "少し困難",
                "中程度困難",
                "かなり困難",
                "ひどく困難"
              ]
            },
            {
              "id": "b79305cb-bfdb-4435-a2f5-c3a6d415f59c",
              "question": "お風呂で身体を洗うのはどの程度困難ですか？",
              "answers": [
                "困難でない",
                "少し困難",
                "中程度困難",
                "かなり困難",
                "ひどく困難"
              ]
            },
            {
              "id": "d9cc4a67-d6f8-407c-8308-e394369b2c16",
              "question": "階段の昇り降りはどの程度困難ですか？",
              "answers": [
                "困難でない",
                "少し困難",
                "中程度困難",
                "かなり困難",
                "ひどく困難"
              ]
            },
            {
              "id": "d76c997c-4ab0-4c36-9d96-9460be3fba23",
              "question": "外に出かけるとき、身だしなみを整えるのは、どの程度困難ですか？",
              "answers": [
                "困難でない",
                "少し困難",
                "中程度困難",
                "かなり困難",
                "ひどく困難"
              ]
            },
            {
              "id": "1b643847-138d-4078-a5c3-033a2ef91498",
              "question": "休まずにどれくらい歩き続けることができますか？（もっとも近いものを選んでください）。　",
              "answers": [
                "つらくない",
                "少しつらい",
                "中程度つらい",
                "かなりつらい",
                "ひどくつらい"
              ]
            },
            {
              "id": "35571809-50d3-44c3-9af1-1e68cea1a291",
              "question": "隣・近所に外出するのはどの程度困難ですか？",
              "answers": [
                "困難でない",
                "少し困難",
                "中程度困難",
                "かなり困難",
                "ひどく困難"
              ]
            },
            {
              "id": "437034e3-f6f2-4a63-bd8f-80ea9514eacc",
              "question": "2Kg程度の買い物(1リットルの牛乳パック2個程度）をして持ち帰ることはどの程度困難ですか？",
              "answers": [
                "困難でない",
                "少し困難",
                "中程度困難",
                "かなり困難",
                "ひどく困難"
              ]
            },
            {
              "id": "5e0a9c6c-4d2a-4768-859d-107b9a40e85c",
              "question": "電車やバスを利用して外出するのはどの程度困難ですか？",
              "answers": [
                "困難でない",
                "少し困難",
                "中程度困難",
                "かなり困難",
                "ひどく困難"
              ]
            },
            {
              "id": "c793b114-63b9-4829-8949-a799c0ec3be0",
              "question": "家の軽い仕事（食事の準備や後始末、簡単なかたづけなど）は、どの程度困難ですか？　",
              "answers": [
                "困難でない",
                "少し困難",
                "中程度困難",
                "かなり困難",
                "ひどく困難"
              ]
            },
            {
              "id": "49a25c1b-b920-413a-88c4-e8430f034b03",
              "question": "家のやや重い仕事（掃除機の使用、ふとんの上げ下ろしなど）は、どの程度困難ですか？",
              "answers": [
                "困難でない",
                "少し困難",
                "中程度困難",
                "かなり困難",
                "ひどく困難"
              ]
            },
            {
              "id": "3678c11e-f0f4-49b7-8dd3-75b8beffb066",
              "question": "スポーツや踊り（ジョギング、水泳、ゲートボール、ダンスなど）は、どの程度困難ですか？",
              "answers": [
                "困難でない",
                "少し困難",
                "中程度困難",
                "かなり困難",
                "ひどく困難"
              ]
            },
            {
              "id": "d5202a89-5589-4f3e-8585-e69ded3e3b6e",
              "question": "親しい人や友人とのお付き合いを控えていますか？",
              "answers": [
                "困難でない",
                "少し困難",
                "中程度困難",
                "かなり困難",
                "ひどく困難"
              ]
            },
            {
              "id": "a62c77b0-8b0f-41f5-95dc-91618fac1a7f",
              "question": "地域での活動やイベント、行事への参加を控えていますか？",
              "answers": [
                "控えていない",
                "少し控えている",
                "中程度控えている",
                "かなり控えている",
                "全く控えている"
              ]
            },
            {
              "id": "8ce865e8-bf51-4860-ab91-683de75dc1d3",
              "question": "家の中で転ぶのではないかと不安ですか？",
              "answers": [
                "不安はない",
                "少し不安",
                "中程度不安",
                "かなり不安",
                "ひどく不安"
              ]
            },
            {
              "id": "11abc50a-575a-4216-adfe-7828e7182602",
              "question": "先行き歩けなくのではないかと不安ですか？",
              "answers": [
                "不安はない",
                "少し不安",
                "中程度不安",
                "かなり不安",
                "ひどく不安"
              ]
            },
            {
              "id": "0e7ea6e2-fa82-4296-809e-039112a34786",
              "question": "急ぎ足で歩くのはどの程度困難ですか？",
              "answers": [
                "困難でない",
                "少し困難",
                "中程度困難",
                "かなり困難",
                "ひどく困難"
              ]
            }
          ]
        },
        "id": "3f613101-4a9e-4112-ae23-4d362c366035",
        "partitionKey": "802872c4-1813-4df8-a032-1407362c94a2",
        "_rid": "TntYAOILZTcSAAAAAAAAAA==",
        "_self": "dbs/TntYAA==/colls/TntYAOILZTc=/docs/TntYAOILZTcSAAAAAAAAAA==/",
        "_etag": "\"6a00a4a2-0000-2300-0000-638daeb50000\"",
        "_attachments": "attachments/",
        "_ts": 1670229685
      }),
    )
  }),
  rest.get('/users', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json([
        {
          id: 1,
          name: 'foo',
        },
        {
          id: 2,
          name: 'bar',
        }
      ]),
    )
  }),
]
