import DefaultHelmet from "../../component/head/defaulthelmet"
import DefaultHeader from "../../component/header/defaultheader"
import LeftSideBar from '../../component/leftsidebar'

const Dashboard = () =>{
  return(
    <>
      <DefaultHelmet title={ 'dashboard' }/>
      <DefaultHeader />
      <div className="flex">
        <LeftSideBar />
          <div className="p-4">
            <div className="py-4">
              ログイン後管理ページ
            </div>
            <div className="grid grid-cols-3 gap-2">
              <GridCard text= '質問の作成' />
              <GridCard text= 'アンケートの作成' link = ''/>
              <GridCard text= '広告の作成' />
              <GridCard text= '実施済みアンケートを見る' />
            </div>
          </div>
      </div>
    </>
  )
}

const GridCard = (props) => {
  return (
    <a className="p-4 border" href={props.link}>
      {props.text}
    </a>
  )
}

export default Dashboard