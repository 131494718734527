
import DefaultHelmet from '../component/head/defaulthelmet'
import { Link } from 'react-router-dom'

const Home = () => {
  return(
    <>
    <DefaultHelmet
      title={ 'helmet' }
      description={ 'desc' }
      OGPimage={'aaa'}
    />
    <header className="text-4xl p-4 bg-green-500 pt-8">
      <div className='flex justify-between px-4'>
      <div>
      </div>
      <Link to='/login'>
        <div className='bg-white text-green-500 font-bold py-2 px-4 rounded text-base'>
          LOGIN
        </div>
      </Link>
      </div>
    </header>
    <div className='main flex flex-col justify-center w-full'>
      <header className='w-full bg-green-500 text-center'>
        <div className='p-32 w-3/4 mx-auto'>
          <h1 className='text-9xl font-serif'>
            QRSurvay
          </h1>
          <div className='text-3xl pt-4'>
            アンケートやテストをしてみませんか？
          </div>
          <div className='text-base'>
            デジタル・デバイド層へのアンケートでデータドリブンな会社を目指していきましょう。
          </div>
        </div>
      </header>
      <div className='bg-green-500 text-center pb-16'>
        <button className='bg-white text-green-500 font-bold py-2 px-4 rounded text-base'>
          営業担当者にお問い合わせ
        </button>
      </div>
      <div className='h-screen w-full bg-green-200'>
        <div className='grid grid-cols-3 p-32 pt-80 w-3/4 mx-auto '>
          <div className='p-4 '>
            <header className='text-lg'>
              個人に合わせたアンケートの配信
            </header>
            <div className='text-sm'>
              個人を識別した紙アンケートの配信が可能です。
            </div>
          </div>
          <div className='p-4'>
            <header className='text-lg'>
              選択式テストのデータ化
            </header>
            <div className='text-sm'>
              簡単な選択式テストも個別に配信が可能です。
            </div>
          </div>
          <div className='p-4'>
            <header className='text-lg'>
              広告システム
            </header>
            <div className='text-sm'>
              他社の同意の元、広告を掲載することが可能です。
            </div>
            <div className='text-sm'>
              広告入りのアンケートを配布することで利益が得られます。
            </div>
            <div className='text-sm'>
              勿論、子供用のテストに広告を入れない選択をすることが出来ます。
            </div>
          </div>
        </div>
      </div>
      <div className='h-screen w-full bg-green-100'>
        <div className='p-32 w-3/4 mx-auto'>
          <div>
            <h2 className='text-5xl'>
              導入は簡単、お手持ちのスキャナーとPCだけ。
            </h2>
            <div className='text-2xl pt-6'>
              サイトにログインして、印刷。
            </div>
            <div className='text-2xl'>
              自宅や職場でPDF出力。持っていって印刷も可能。
            </div>
          </div>
          <div className='pt-32'>
            <h2 className='text-5xl'>
              個人情報対策もバッチリ。
            </h2>
            <div className='text-2xl pt-6'>
              拾ったアンケートから個人情報を知られない。
            </div>
            <div className='text-2xl'>
              誰がアンケートを読み込んでも、発行者の元へ。
            </div>
          </div>
        </div>
      </div>
      <div className='h-screen w-full bg-white'>
        <div className='p-32 w-3/4 mx-auto'>
          <div className='text-4xl p-4'>
            どういう人・業態向けのサービスですか？
          </div>
          <div className='p-4 text-lg'>
            <div className='p-2 text-lg'>紙媒体でのテスト・アンケートをしている</div>
            <div className='p-2 text-lg'>ユーザー・顧客の傾向や動向を知りたい</div>
            <div className='p-2 text-lg'>顧客にサービスや商品の満足度を聞きたい</div>
            <div className='p-2 text-lg'>顧客に会社・商品を知ってもらいたい</div>
            <div className='p-2 text-lg'>アンケートハガキのリプレース</div>
            <div className='p-2 text-lg'>求人広告(当社が求人の仲介することは現在ありません。)</div>
          </div>
        </div>
      </div>
      <div className='bg-green-500 text-center pt-16'>
        <button className='bg-white text-green-500 font-bold py-2 px-4 rounded text-base'>
          営業担当者にお問い合わせ
        </button>
      </div>
    </div>
    <footer className='bg-green-500 text-center p-16'>
        ©Altplan.co.jp
    </footer>
    </>
  )
}
export default Home;
