import { useFieldArray, useForm } from 'react-hook-form'
import { useState } from 'react'
import DefaultHeader from '../../../component/header/defaultheader';
import LeftSideBar from '../../../component/leftsidebar';

const ADDQuestion = () => {
  const { register, handleSubmit, control , formState: { errors } } = useForm({
    defaultValues: {
      answer: [{ value:'' },{ value:'' }]
    }
  });
  const { fields, append, remove } = useFieldArray({ control, name: 'answer' });
  const [ error , setError] = useState()
  const [ id , setID] = useState()
  const [ loading, setLoading ] = useState()
  const handleClick = async(data) => {
    setLoading(true)
    const formData = new FormData()
    const answers = []
    data.answer.map((item) => answers.push(item.value))
    formData.append('question',data.question)
    formData.append('answers',JSON.stringify(answers))
    fetch('/api/question/add',{
      method: 'POST',
      body: formData
    }).then(async(d) => {
      setLoading(false)
      if(d.status !== 200) return setError({error:'サーバーエラー'})
      return setID(await d.json())
    })
  }
  return (
    <>
      <DefaultHeader></DefaultHeader>
      <div className="flex">
      <LeftSideBar></LeftSideBar>
      <div className="w-full max-w-lg">
      <form
        className="bg-white px-8 py-4 m-4 flex flex-col"
        onSubmit={ handleSubmit(handleClick) }>

        <div className="font-semibold text-lg py-2">
          質問
          </div>
          <input
            className="appearance-none border rounded w-full p-3"
            {...register("question" , { required: true })}
            type="text" placeholder="Q."
          />
          <div className='flex justify-between py-2'>
            <div className='font-semibold text-lg py-2'>答え</div>
            <button onClick={() => {
              append({ value: '' })
            }}
              className='btn-default'>答えを増やす
            </button>
          </div>
            { fields.map((field, index) => {
              if(index === 5){
                remove(index)
                return setError({error:'答えは5個までです。'})
              }
              return (
                <div key={field.id} className='flex w-full justify-between py-1'>
                  <input
                    {...register(`answer.${index}.value` , { required: true })}
                    placeholder = {'A.' + (index + 1)}
                    className = 'p-4 w-2/3 border rounded'
                  />
                  <button
                    className='btn-default'
                    onClick={() => {
                      if(fields.length <= 2){
                        return setError({error:'答えは2個以上です。'})
                      }
                      remove(index)
                  }}>質問を減らす</button>
                </div>
              )
            })}
        { error
          ? <button className='btn-error my-8' onClick={()=>setError()}>{error.error}</button>
          : <>
            { id
              ? <button className='btn-default my-8'>送信完了</button>
              : <>
                { loading
                  ? <button className='btn-default my-8'>送信中...</button>
                  : <button type='submit' className='btn-default my-8'>送信</button>
                }
                </>
            }
            </>
        }
      </form>
      </div>
      </div>
    </>
  );
}
export default ADDQuestion;