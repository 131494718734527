import { Link } from "react-router-dom";

const LeftSideBar = () => {
  return(
    <aside className="w-64 bg-green-50">
      <header className="text-2xl py-10">
        dashboard(name)
      </header>
      <Item text='dashboard' path='/dashboard'></Item>
      <Item text='質問を作成' path='/question/add'/>
      <Item text='質問一覧' path='/question/list'></Item>
      <Item text='アンケート作成' path='/survay/add'></Item>
      <Item text='アンケート一覧' path='/survay/list'></Item>
      <Item text='設定'></Item>
    </aside>
  )
}
export default LeftSideBar;

const Item = (props) =>{
  return(
    <Link to={props.path}>
      <div className ="overflow-y-auto py-4 px-4 bg-white rounded-l-2xl hover:bg-green-500 hover:text-white">
        {props.text}
      </div>
    </Link>
  )
}