import React, { Children } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { worker } from './mocks/browser';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async'
import Home from './pages/index'
import LoginForm from './pages/login';
import CreateADs from './pages/createADs';
import ADDQuestion from './pages/question/add';
import Dashboard from './pages/dashboard';
import AddSurvay from './pages/survay/add';
import GETQuestionList from './pages/question/list';
import GetSurvayList from './pages/survay/list';
import { GetSurvay } from './pages/survay/get';

if (process.env.NODE_ENV === 'development') {
  const { worker } = require('./mocks/browser')
  worker.start()
}


const router = createBrowserRouter([
  {
    path:'/',
    element:<Home />,
    errorElement:<>error</>,
  },{
    path:'login',
    element:<LoginForm />,
    errorElement:<>error</>,
  },{
    path:'logout',
    element:<>logout</>
  },{
    path:'dashboard',
    element:<Dashboard />
  },{
    path:'ad',
    element:<>ad index</>
  },{
    path:'ad/add',
    element:<CreateADs />
  },{
    path:'ad/list',
    element:<LoginForm />
  },{
    path:'ad/:id',
    element:<>ad/id</>
  },{
    path:'question',
    element:<>question index</>
  },{
    path:'question/add',
    element:<ADDQuestion />
  },{
    path:'question/list',
    element:<GETQuestionList />
  },{
    path:'question/:id',
    element:<>question/id</>
  },{
    path:'user',
    element:<>user index</>
  },{
    path:'user/add',
    element:<>user/add</>
  },{
    path:'user/list',
    element:<>user/list</>
  },{
    path:'user/:id',
    element:<>userid</>
  },{
    path:'survay',
    element:<>survay index</>
  },{
    path:'survay/add',
    element:<AddSurvay />
  },{
    path:'survay/list',
    element:<GetSurvayList />
  },{
    path:'survay/print',
    element:<>survay/print</>
  },{
    path:'survay/:id',
    element:<GetSurvay />
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <RouterProvider router={router}/>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
