import { useEffect, useState } from 'react'
import ContentH2 from '../../../component/contenth2'
import ContentH2Description from '../../../component/contenth2description'
import DefaultHelmet from '../../../component/head/defaulthelmet'
import { v4 as uuidv4 } from 'uuid'
import useSWR from 'swr'
import { fetcher } from '../../../func/fetcher'
import { ErrorBoundary } from 'react-error-boundary'
import DefaultHeader from '../../../component/header/defaultheader'
import LeftSideBar from '../../../component/leftsidebar'

const data = [
  {
    "id": "611117b4-8848-4c1a-8587-10fc190a22ab",
    "question": "背中・腰・お尻のどこかに痛みがありますか？",
    "answers": [
      "痛くない",
      "少し痛い",
      "中程度痛い",
      "かなり痛い",
      "ひどく痛い"
    ]
  },
  {
    "id": "96b75cb3-8a2e-44fe-abc8-48b9641337ef",
    "question": "頚・肩・腕・手のどこかに痛み（しびれも含む）がありますか？",
    "answers": [
      "痛くない",
      "少し痛い",
      "中程度痛い",
      "かなり痛い",
      "ひどく痛い"
    ]
  },
  {
    "id": "9e1ab6e3-a330-4896-bda1-b346354106c3",
    "question": "下股（脚のつけね、太もも、膝、ふくらはぎ、すね、足首、足）のどこかに痛み（しびれも含む）がありますか？",
    "answers": [
      "痛くない",
      "少し痛い",
      "中程度痛い",
      "かなり痛い",
      "ひどく痛い"
    ]
  },
  {
    "id": "2039c7b0-d14e-4005-b767-01973364b3f1",
    "question": "ふだんの生活で身体を動かすのはどの程度つらいと感じますか？",
    "answers": [
      "つらくない",
      "少しつらい",
      "中程度つらい",
      "かなりつらい",
      "ひどくつらい"
    ]
  },
  {
    "id": "956d14cb-650f-49a8-964e-49409dd88320",
    "question": "ベッドや寝床から起きたり、横になったりするのはどの程度困難ですか？",
    "answers": [
      "困難でない",
      "少し困難",
      "中程度困難",
      "かなり困難",
      "ひどく困難"
    ]
  },
  {
    "id": "693f1fe9-6978-4d16-b356-7e2a641dedb6",
    "question": "腰掛から立ち上げるのはどの程度困難ですか？",
    "answers": [
      "困難でない",
      "少し困難",
      "中程度困難",
      "かなり困難",
      "ひどく困難"
    ]
  },
  {
    "id": "bee59235-d7cd-41bc-928c-ab0873a0eb10",
    "question": "家の中を歩くのはどの程度困難ですか？",
    "answers": [
      "困難でない",
      "少し困難",
      "中程度困難",
      "かなり困難",
      "ひどく困難"
    ]
  },
  {
    "id": "6c319f75-f7fb-49fe-87b3-a3ac43f66e88",
    "question": "シャツを着たり脱いだりするのはどの程度困難ですか？",
    "answers": [
      "困難でない",
      "少し困難",
      "中程度困難",
      "かなり困難",
      "ひどく困難"
    ]
  },
  {
    "id": "7a8c0192-a605-4d32-bea9-2fb476bcb59e",
    "question": "ズボンやパンツを着たり脱いだりするのはどの程度困難ですか？",
    "answers": [
      "困難でない",
      "少し困難",
      "中程度困難",
      "かなり困難",
      "ひどく困難"
    ]
  },
  {
    "id": "bea13aa1-f552-4ac0-be08-cc773ffcf2be",
    "question": "トイレで用足しをするのはどの程度困難ですか？",
    "answers": [
      "困難でない",
      "少し困難",
      "中程度困難",
      "かなり困難",
      "ひどく困難"
    ]
  },
  {
    "id": "b79305cb-bfdb-4435-a2f5-c3a6d415f59c",
    "question": "お風呂で身体を洗うのはどの程度困難ですか？",
    "answers": [
      "困難でない",
      "少し困難",
      "中程度困難",
      "かなり困難",
      "ひどく困難"
    ]
  },
  {
    "id": "d9cc4a67-d6f8-407c-8308-e394369b2c16",
    "question": "階段の昇り降りはどの程度困難ですか？",
    "answers": [
      "困難でない",
      "少し困難",
      "中程度困難",
      "かなり困難",
      "ひどく困難"
    ]
  },
  {
    "id": "0e7ea6e2-fa82-4296-809e-039112a34786",
    "question": "急ぎ足で歩くのはどの程度困難ですか？",
    "answers": [
      "困難でない",
      "少し困難",
      "中程度困難",
      "かなり困難",
      "ひどく困難"
    ]
  },
  {
    "id": "d76c997c-4ab0-4c36-9d96-9460be3fba23",
    "question": "外に出かけるとき、身だしなみを整えるのは、どの程度困難ですか？",
    "answers": [
      "困難でない",
      "少し困難",
      "中程度困難",
      "かなり困難",
      "ひどく困難"
    ]
  },
  {
    "id": "1b643847-138d-4078-a5c3-033a2ef91498",
    "question": "休まずにどれくらい歩き続けることができますか？（もっとも近いものを選んでください）。　",
    "answers": [
      "つらくない",
      "少しつらい",
      "中程度つらい",
      "かなりつらい",
      "ひどくつらい"
    ]
  },
  {
    "id": "35571809-50d3-44c3-9af1-1e68cea1a291",
    "question": "隣・近所に外出するのはどの程度困難ですか？",
    "answers": [
      "困難でない",
      "少し困難",
      "中程度困難",
      "かなり困難",
      "ひどく困難"
    ]
  },
  {
    "id": "437034e3-f6f2-4a63-bd8f-80ea9514eacc",
    "question": "2Kg程度の買い物(1リットルの牛乳パック2個程度）をして持ち帰ることはどの程度困難ですか？",
    "answers": [
      "困難でない",
      "少し困難",
      "中程度困難",
      "かなり困難",
      "ひどく困難"
    ]
  },
  {
    "id": "5e0a9c6c-4d2a-4768-859d-107b9a40e85c",
    "question": "電車やバスを利用して外出するのはどの程度困難ですか？",
    "answers": [
      "困難でない",
      "少し困難",
      "中程度困難",
      "かなり困難",
      "ひどく困難"
    ]
  },
  {
    "id": "c793b114-63b9-4829-8949-a799c0ec3be0",
    "question": "家の軽い仕事（食事の準備や後始末、簡単なかたづけなど）は、どの程度困難ですか？　",
    "answers": [
      "困難でない",
      "少し困難",
      "中程度困難",
      "かなり困難",
      "ひどく困難"
    ]
  },
  {
    "id": "49a25c1b-b920-413a-88c4-e8430f034b03",
    "question": "家のやや重い仕事（掃除機の使用、ふとんの上げ下ろしなど）は、どの程度困難ですか？",
    "answers": [
      "困難でない",
      "少し困難",
      "中程度困難",
      "かなり困難",
      "ひどく困難"
    ]
  },
  {
    "id": "3678c11e-f0f4-49b7-8dd3-75b8beffb066",
    "question": "スポーツや踊り（ジョギング、水泳、ゲートボール、ダンスなど）は、どの程度困難ですか？",
    "answers": [
      "困難でない",
      "少し困難",
      "中程度困難",
      "かなり困難",
      "ひどく困難"
    ]
  },
  {
    "id": "d5202a89-5589-4f3e-8585-e69ded3e3b6e",
    "question": "親しい人や友人とのお付き合いを控えていますか？",
    "answers": [
      "困難でない",
      "少し困難",
      "中程度困難",
      "かなり困難",
      "ひどく困難"
    ]
  },
  {
    "id": "a62c77b0-8b0f-41f5-95dc-91618fac1a7f",
    "question": "地域での活動やイベント、行事への参加を控えていますか？",
    "answers": [
      "控えていない",
      "少し控えている",
      "中程度控えている",
      "かなり控えている",
      "全く控えている"
    ]
  },
  {
    "id": "8ce865e8-bf51-4860-ab91-683de75dc1d3",
    "question": "家の中で転ぶのではないかと不安ですか？",
    "answers": [
      "不安はない",
      "少し不安",
      "中程度不安",
      "かなり不安",
      "ひどく不安"
    ]
  },
  {
    "id": "11abc50a-575a-4216-adfe-7828e7182602",
    "question": "先行き歩けなくのではないかと不安ですか？",
    "answers": [
      "不安はない",
      "少し不安",
      "中程度不安",
      "かなり不安",
      "ひどく不安"
    ]
  },
  {
    "id": "f8ff64d5-7aa1-4822-b1d8-8a4ab921b1be",
    "question": "テストテキスト",
    "answers": [
      "答え1",
      "答え2"
    ]
  },
  {
    "id": "0a65d613-1cee-4e92-95a7-c8d225902a73",
    "question": "テストテキスト",
    "answers": [
      "答え1",
      "答え2",
      "答え3"
    ]
  },
  {
    "id": "cb255d3c-28f5-4cb2-8b36-c402c96eb33f",
    "question": "テストテキスト",
    "answers": [
      "答え1",
      "答え2",
      "答え3"
    ]
  },
  {
    "id": "55e6f453-2344-4c8f-90ac-2eba39b18291"
  },
  {
    "id": "52d8aaa9-ac51-4fa1-8c83-85f20a9a838e",
    "question": "質問",
    "answers": [
      "a",
      "i"
    ]
  },
  {
    "id": "908653b8-ff98-47db-9d0d-12353a937d62",
    "question": "質問",
    "answers": [
      "a",
      "i"
    ]
  },
  {
    "id": "1b628df0-aef9-4a08-b4f7-fcb0e9d8bd5f"
  },
  {
    "id": "7132dbb7-d5c4-4b70-9779-42f643407df5"
  },
  {
    "id": "5ddad0ba-cf8c-4fdf-af36-0d9c3372c5a2"
  },
  {
    "id": "e1e97672-6859-41bc-8318-9082f0344290"
  },
  {
    "id": "329260f5-6055-43d5-bca8-6171afde9785",
    "question": "質問",
    "answers": [
      "A1",
      "A2"
    ]
  },
  {
    "id": "25cabe4c-0a3b-4dff-8849-360ef71052af",
    "question": "質問2",
    "answers": [
      "A1",
      "A2"
    ]
  },
  {
    "id": "a4b0151c-948f-4a09-b9dc-be445d395002",
    "question": "A2",
    "answers": [
      "A2",
      "A2"
    ]
  },
  {
    "id": "57337fe6-1fad-494d-a406-6937ca4f9376"
  },
  {
    "id": "913d85e4-921e-461d-a78b-10c69ed3306e"
  }
]

const GETQuestionList = () => {
  // api fetch
  const url = "/api/question/list"
  const { data, error } = useSWR(url, fetcher)
  //const error =''

  return(
    <>
    <DefaultHelmet
      title={ '質問リスト' }
      description={ '質問リスト' }
    />
    <DefaultHeader></DefaultHeader>
    <div className="flex">
    <LeftSideBar></LeftSideBar>
    <div className='flex flex-col w-full'>
      <ContentH2 title={'登録されている質問のリストです'} />
      <ContentH2Description text='この一覧表から質問をアーカイブすることが出来ます。'/>
      <div>
        <div className='p-4 my-auto '>
      {
        error
        ? <div>server error</div>
        : <>
          {
            data
            ? <QuestionList data={data}/>
            : <LoadingList />
          }
          </>
      }
        </div>
      </div>
    </div>
    </div>
    <footer className='h-96 mt-auto'>
      footer
    </footer>
    </>
  )
}

const ArchiveButton = (props) => {
  const [error, setError] = useState()
  const [loading,setLoading] = useState()
  const handleClick = async(data) => {
    const formData = new FormData()
    formData.append('id',JSON.stringify(data.id))
    setLoading(true)
    fetch('/api/question/archive',{
      method: 'POST',
      mode:'same-origin',
      body: formData
    }).then(d => {
      if(d.status !== 200){
        setLoading(false)
        return setError(d.status)
      }
      setLoading(false)
      return
    })
  }
  return(
    <>
    { error
      ? <>error</>
      : <> {
        loading
        ? <button onClick={() => handleClick(props.id)} className="btn-default">
            <div className="animate-spin h-6 w-6 border-4 border-green-300 rounded-full border-t-transparent" />
          </button>
        : <button onClick={() => handleClick(props.id)} className="btn-default">
            <div>アーカイブする</div>
          </button>
      }</>
    }
    </>
  )
}

const QuestionList = (props) => {
  return(
  <>
    { props.data.map((item,index) =>
      <div className='flex p-1 justify-between odd:bg-green-200 even:bg-white m-1 rounded' key={item.id}>
        <div className='flex-col'>
          <h2 className='font-semibold'>
            {item?.question}
          </h2>
          <div className='text-sm'>
            {item.answers?.map(item => item)}
          </div>
        </div>
        <div>
          <GetDetailButton key={item.id} id={item.id}/>
        </div>
      </div>
    )}
  </>
  )
}


const GetDetailButton = (props) => {
  const [data,setData] = useState()
  const [error,setError] = useState()
  const [loading,setLoading] = useState()
  const getData = async(id) =>{
    setLoading(true)
    const formData = new FormData()
    console.log(id)
    formData.append('id',id)
    fetch('/api/question/get',{
      method: 'POST',
      mode:'same-origin',
      body: formData
    }).then(async(d) => {
      if(d.status !== 200){
        setLoading(false)
        return setData({
          "company": "802872c4-1813-4df8-a032-1407362c94a2",
          "question": "背中・腰・お尻のどこかに痛みがありますか？",
          "answers": [
            "痛くない",
            "少し痛い",
            "中程度痛い",
            "かなり痛い",
            "ひどく痛い"
          ],
          "id": "611117b4-8848-4c1a-8587-10fc190a22ab",
          "partitionKey": "802872c4-1813-4df8-a032-1407362c94a2",
          "_rid": "TntYAJ-SHmIHAAAAAAAAAA==",
          "_self": "dbs/TntYAA==/colls/TntYAJ-SHmI=/docs/TntYAJ-SHmIHAAAAAAAAAA==/",
          "_etag": "\"3601a5bf-0000-2300-0000-637229310000\"",
          "_attachments": "attachments/",
          "_ts": 1668426033
        })
        return setError(d.status)
      }
      setLoading(false)
      return setData(await d.json())
    })
  }
  return(
    <>
    {
      error
      ? <Error />
      : <>
          {
            loading
            ? <Loading />
            : <>
                {
                  data
                  ? <>
                      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
                        <div className='fixed inset-0 z-10 overflow-y-auto'>
                          <div class="flex min-h-full  justify-center p-4 text-center items-center">
                            <div class="relative transform overflow-hidden rounded bg-white text-left transition-all my-8 w-full max-w-lg p-4 gap-2 grid grid-cols-2">
                              { Object.keys(data).map(itm =>
                                  <>
                                  <div className='p-1'>
                                    {itm}
                                  </div>
                                  <div className='p-2'>
                                    {data[itm]}
                                  </div>
                                  </>
                              )}
                            <button className='btn-default' onClick={()=>setData()}>
                              閉じる
                            </button>
                            <ArchiveButton id={props.id}></ArchiveButton>
                          </div>
                        </div>
                      </div>
                    </>
                  : <button onClick={() => getData(props.id)} className="btn-default">
                      <div>詳細</div>
                    </button>
                }
              </>
          }
        </>
    }
    </>
  )
}

const Loading = () => {
  return(
    <>
    <div className='btn-default'>
      Loading...
    </div>
    </>
  )
}


const Error = () => {
  return(
    <>
    <div className='btn-error'>
      Error
    </div>
    </>
  )
}


const LoadingList = () => {
  return(
    <>
      <div className='p-1 bg-slate-50'>
        loading...
      </div>
    </>
  )
}


export default GETQuestionList;