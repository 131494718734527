
import { Helmet } from 'react-helmet-async'
import { useLocation } from "react-router-dom"


const DefaultHelmet = (props) => {
  const u = useLocation()
  const url = process.env.REACT_APP_DOMAIN + u.pathname
  const sitename = process.env.REACT_APP_SITENAME
  const siteOwnerTwitterUserName = process.env.REACT_APP_SITEOWNERTWITTERUSERNAME
  const type = () => {
    if(u.pathname === '/') return 'website'
    return 'article'
  }
  return(
    <Helmet>
      <html lang='jp' />
      <title>{props.title}</title>
      <meta name='description' content={ props.description } />
      <meta property="og:url" content={ url } />
      <meta property="og:title" content={ props.title } />
      <meta property="og:type" content={ type() } />
      <meta property="og:description" content={ props.description } />
      <meta property="og:site_name" content={ sitename } />
      <meta property="og:locale" content="ja_JP" />
      <meta property="fb:app_id" content="appIDを入力" />
      <meta name="twitter:site" content={ siteOwnerTwitterUserName } />
      <meta name="twitter:title" content={ props.title } />
      <meta name="twitter:description" content={ props.description } />

      {
        (props.OGPimage)&&
        (
          <>
            <meta property="og:image" content={props.OGPimage} />
            <meta name="twitter:image" content={props.OGPimage} />
            <meta name="twitter:card" content="summary_large_image" />
          </>
        )
      }
      {
        (props.twitterUserName) && (
          <meta name="twitter:creator" content={props.twitterUserName} />
        )
      }


    </Helmet>
  )
}
export default DefaultHelmet;