import { useRef,useState,useEffect } from 'react'
import QRCode from 'qrcode'
import { v4 as uuidv4 } from 'uuid';

export const ReactPdfPrint = (props) => {
  const componentRef = useRef()
  const data = props.data
  const ids = props.ids

  return (
    <>
    <style type="text/css">
      {`
        @page {
          size: A4 portrait;
          @bottom-left {
            content: counter(page) ' of ' counter(pages);
          }
        }
        @media all {
          .pagebreak {
            display:none;
          }
          .content {
            display:none;
          }
        }
        @media print {
          .content {
            display:block
          }
          .avoidpagebreak {
            // background-color:pink;
            break-inside: avoid;
          }
          .header, .header-space {
            height : 100px;
          }
          .footer, .footer-space {
            height: 100px;
          }
          .header {
            top: 0;
          }
          .footer {
            bottom: 0;
          }
        }
      `}
    </style>
      <div>
        <div ref = {componentRef} className='content'>
          <PrintData data={data} ids={ids} ></PrintData>
        </div>
      </div>
    </>
  )

}

const PrintData = (props) => {
  return(<>
    <div className='h-full mx-auto' >
    { props.ids.map(id=>
    <table className='w-full break-after-page' key={id}>
      <thead className="header-space w-full"><tr><td>
        <HeaderBlock title={props.data.item.title} id={id}/>
      </td></tr></thead>
      <tbody><tr><td>
        <div className='flex flex-col justify-between'>
        { props.data.item.survay?.map((sv,i) => {
          if(((i+1)%10)===0 || (i+1)===props.data.item.survay.length ){
            return(
              <div key={sv.id}>
                <QuestionBlock survay={sv} index={i} key={sv.id} />
                <FooterBlock id={id} key={id}/>
              </div>
          )}else{
              return (
              <div key={sv.id}>
                <QuestionBlock survay={sv} index={i} key={sv.id} />
              </div>
          )}}
        )}
        </div></td></tr>
      </tbody>
      </table>
      )}

  </div>
  </>)
}

const HeaderBlock = (props) => {
  return (
    <div className='w-full flex justify-between p-4 bg-slate-100 items-center'>
      <h2 className='text-3xl p-4'>
        {props.title}
      </h2>
      <MakeQR id={props.id} height={50} width={50} className="text-right" />
    </div>
  )
}

const QuestionBlock = (props) => {
  return (
    <div className='avoidpagebreak px-4 flex flex-col justify-center h-[5.5rem]'>
      <div className='px-4 py-2 text-lg'>
        {props.index+1}.{props.survay.question}
      </div>
      <div className='px-8 py-2'>
        <div className='flex justify-between'>
          { props.survay.answers.map(answer =>
            <div className='text-sm' key={uuidv4()}>
              {answer}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const MakeQR = (props) => {
  const [state, setState] = useState()
    useEffect(() => {
      (async() => {
        setState(await QRCode.toDataURL(props.id,{errorCorrectionLevel : "H"}))
      })()
    },[props.id]);
  return(
    <img src={state} alt='qrcode' width={props.width} height={props.height} className='overflow-visible'/>
  )
}

const FooterBlock = (props) => {
  return (
    <div className="flex justify-end p-4 w-full h-[10rem] footer break-after-page mb-0 h-">
      <h2 className='text-sm p-4'>
        System:©Altplan.co.jp
      </h2>
    </div>
  )
}
