import { useEffect, useState,useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import DefaultHelmet from '../../../component/head/defaulthelmet';
import DefaultHeader from '../../../component/header/defaultheader';
import LeftSideBar from '../../../component/leftsidebar';
import { ReactPdfPrint } from '../../../func/print/printfornewuser';
import { useReactToPrint } from 'react-to-print'

export const GetSurvay = () => {
  const { id } = useParams();
  return (
    <>
    <DefaultHelmet
      title={ '質問リスト' }
      description={ '質問リスト' }
    />
    <DefaultHeader></DefaultHeader>
    <div className="flex">
      <LeftSideBar></LeftSideBar>
      <GetData id={id}></GetData>
    </div>
    </>
  )
}

const GetData = (props) => {
  const [error, setError] = useState()
  const [loading, setLoading] = useState()
  const [data, setData] = useState()
  useEffect(() => {
    setLoading(true)
    const formData = new FormData()
    formData.append('id', props.id)
    fetch('/api/survay/get',{
      method: 'POST',
      body: formData
    })
      .then(async(res) => {
        try{
          setData(await res.json())
        }catch{
          setError(true)
        }
      })
      .catch(e => setError(true))
    setLoading(false)
  },[props])
  return(
    <div className='flex flex-col w-full'>
    { error
      ? <ErrorMessage></ErrorMessage>
      : <>
        {loading
          ? <Loading></Loading>
          : <>{ data
            ? <DataToJSX data={data}></DataToJSX>
            : <>no data</>
          }
          </>
        }
        </>
    }
    </div>
  )
}

const ErrorMessage = () => {
  return(
    <div>
      <h2 className='px-4 py-2 font-semibold text-2xl'>サーバーエラー</h2>
      <p className='px-4 py-2'>サーバーか通信に問題がありそうです。</p>
    </div>
  )
}

const Loading = () => {
  return(
    <div>
      <h2 className='px-4 py-2 font-semibold text-2xl'>通信中</h2>
      <p className='px-4 py-2'>少々お待ち下さい。</p>
    </div>
  )
}

const DataToJSX = (props) => {
  const [ num, setNum ]=useState()
  const [ ids, setID] = useState([])
  const [ loading, setLoading] = useState()
  const [ error,setError] = useState()
  const componentRef = useRef()
  const onBeforeGetContentResolve = useRef()

  const handleOnBeforeGetContent = useCallback(()=>{
    return new Promise(async(resolve)=>{
      onBeforeGetContentResolve.current = resolve
      await gettingdata()
      setTimeout(() => {
        setLoading(false);
        resolve();
      }, 1000);
    })
  },[ids])

  const gettingdata = async() => {
    setLoading(true)
    console.log('gettingada')
    const formData = new FormData()
    formData.append('num',num)
    formData.append('id',props.data.id)
    fetch('/api/survay/new',{
      method: 'POST',
      body: formData
    })
    .then(async(res) => {
      try{
        const j = await res.json()
        setID(j)
      }catch{
        setError(true)
      }
    })
    .catch(e => setError(true))
  }
  const handlePrint = useReactToPrint({
    content : () => componentRef.current,
    documentTitle : props.data.item.title,
    onBeforeGetContent : handleOnBeforeGetContent,
  })
  console.log(ids)
  return(
    <>
    <div>
      <div className='flex justify-between'>
        <h2 className='px-4 py-2 font-semibold text-2xl'>{props.data.item.title}</h2>
        <div>
          <input type='number' className='font-bold py-2 px-4 mx-2 rounded border' onChange={(e)=> setNum(e.target.value)}></input>
          <button onClick={handlePrint} className='btn-default'>印刷</button>
          <div ref = {componentRef} className='content'>
            <ReactPdfPrint data={props.data} ids={ids}></ReactPdfPrint>
          </div>
        </div>
      </div>
      <div className='px-4 py-2'>
        {props.data.item.survay?.map(item =>
          <div className='p-4'>
            <h2 className='text-lg'>{item.question}</h2>
            <div className='flex'>
              {item.answers?.map(item =>
                <div className='text-sm'>
                  {item}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
    </>
  )
}